module.exports = new Promise(resolve => {
      // This part depends on how you plan on hosting and versioning your federated modules
      const remoteUrl = window.location.origin + '/mfe/engage/remoteEntry.js';
      const script = document.createElement('script');
      script.src = remoteUrl;
      script.defer = true;
      script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
          get: (request) => window.engage.get(request),
          init: (arg) => {
            try {
              return window.engage.init(arg);
            } catch(e) {
              console.log('remote container already initialized');
            }
          }
        }
        resolve(proxy);
      };

      // Create a link to preload the remoteEntry file.
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'script';
      link.href = remoteUrl;

      const title = document.querySelector('title');
      title.after(link);

      // inject this script with the src set to the versioned remoteEntry.js
      document.head.appendChild(script);
    });
    ;